<template>
  <b-overlay
    :show="show && !$store.state.auth.isProfileCalled"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <div v-if="!show && $store.state.auth.isProfileCalled">
      <b-row class="d-flex flex-wrap flex-column justify-content-center align-items-center">
        <b-col
          md="12"
          sm="12"
          class="mt-1 d-flex flex-wrap justify-content-center"
        >
          <div style="height: 100%;">
            <b-alert
              class="p-2 pb-4"
              style="height: 100%;position: relative;"
              show
              variant="warning"
            >
              <h4> {{ $t('suggest-active-two-factore-login-message') }}</h4>
              <p />
              <br>
              <br>
              <div
                class="w-100 text-center mt-2"
                style="position: absolute;bottom: 10px; width: max-content;"
              >
                <b-button
                  class="mx-auto d-block w-50 w-md-25"
                  :to="'/'+this.$i18n.locale+'/profile/security'"
                >
                  {{ $t('activate') }}
                </b-button>
              </div>
            </b-alert>
          </div>
          <div
            v-for="card in cardsArray"
            :key="card.title"
            :class="(card.total_pending && card.total_pending !=0 )||( card.cardType && card.cardType == 'document' &&
              (card.status == 'pending' || card.status == 'rejected'))?'w-100 w-md-50 p-1':''"
          >
            <div
              v-if="(card.total_pending && card.total_pending !=0 )||( card.cardType && card.cardType == 'document' &&
                (card.status == 'pending' || card.status == 'rejected'))"
              class="w-100"
              style="position: relative;height: 100%;"
            >
              <div style="height: 100%;">
                <b-alert
                  class="p-2 pb-4"
                  style="height: 100%;position: relative;"
                  show
                  :variant="(card.cardType && card.cardType == 'document')?(card.status == 'pending'?'warning':'danger'):'info'"
                >
                  <h4>
                    {{ $t(card.title,{count:card.total_pending?card.total_pending:'',status:$t(card.status)}) }}
                  </h4>
                  <p :class="$store.state.appConfig.layout.skin === 'dark' && !(card.cardType && card.cardType == 'document') && 'text-white'">
                    {{ $t(card.cardType && card.cardType == 'document'?(card.status == 'pending'?card.pendingDescription:card.rejectDescription):card.description) }}
                  </p>
                  <div
                    class="w-100 text-center mt-2"
                    style="position: absolute;bottom: 10px; width: max-content;"
                  >
                    <router-link
                      :to="card.link"
                    >
                      <b-button variant="success">
                        {{ $t('more_details') }}
                      </b-button>
                    </router-link>
                  </div>
                </b-alert>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="!isUserActive && !user_complete">
        <b-col cols="12">
          <b-card>
            <b-card-body>
              <b-row
                align-h="center"
                align-v="center"
                style="flex-direction:row-reverse"
              >
                <b-col
                  cols="12"
                  sm="5"
                  md="3"
                  class="mb-3 mb-sm-0 d-flex justify-content-center"
                >
                  <feather-icon
                    variant="success"
                    icon="CheckCircleIcon"
                    size="150"
                    color="#94d56c"
                  />
                </b-col>
                <b-col
                  cols="12"
                  sm="7"
                  md="9"
                >
                  <h3 class="font-weight-bold mb-4">
                    {{ $t('user_no_active.register_success_confirm_email') }}
                  </h3>
                  <h3> {{ $t('user_no_active.description1') }}</h3>
                  <h3> {{ $t('user_no_active.description2') }}</h3>
                  <h3 class="text-danger">
                    {{ $t('user_no_active.email_process') }}
                  </h3>
                  <div class="mt-5 text-center">
                    <h4> {{ $t('user_no_active.email_resend') }}</h4>
                    <b-button
                      class="text-nowrap"
                      :disabled="sendMaliLoading || !(remainingTime.minutes === 0 && remainingTime.seconds === 0)"
                      variant="primary"
                      @click="resendActivationEmail"
                    >
                      <span>{{ $t('user_no_active.resend_email_btn') }}</span>
                      <span
                        v-if="remainingTime.minutes > 0 || remainingTime.seconds > 0"
                        class="mx-1"
                        dir="rtl"
                      >

                        {{ formatTime(remainingTime.seconds) }}
                        :
                        {{ formatTime(remainingTime.minutes) }}

                      </span>
                    </b-button>
                  </div>
                </b-col>

              </b-row>

            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row
        v-if="isUserActive && !user_complete"
        class="d-flex flex-wrap flex-column justify-content-center align-items-center"
      >
        <b-col
          md="8"
          sm="12"
          class="mt-1"
        >
          <b-card style="height:100%">
            <b-card-body class="p-0 card-center-content">
              <h4>{{ $t(cardInfo.nextStep.header) }}</h4>
              <p>{{ $t(cardInfo.nextStep.description) }}</p>
            </b-card-body>
            <div class="text-center">
              <b-button
                class="p-2 px-4"
                variant="success"
                :to="cardInfo.nextStep.btn_link"
              >
                {{ $t(cardInfo.nextStep.btn_text) }}
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="isUserActive && user_complete && mergedObj.document&&( mergedObj.document.general.status != 'rejected' && mergedObj.document.general.status != 'pending') ">
        <b-col
          md="8"
          sm="12"
          class="mt-1 mx-auto"
        >
          <b-card style="height:100%">
            <b-card-body class="p-0 card-center-content">
              <h2>{{ $t('dashboard_page.user_complete_message') }}</h2>
              <p>{{ $t('dashboard_page.use_platform') }}</p>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import ProfileApis from '@/services/apis/profile'

import appFlow from '@/mixins/appFlow'

const profile = new ProfileApis()
const actvieModules = process.env.VUE_APP_ACTIVE_MODULES.split(',')
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BOverlay,
  },

  mixins: [appFlow],
  data() {
    return {
      sendMaliLoading: false,
      exchangePath: process.env.VUE_APP_EXCHANGE_ADDRESS,
      exchangePage: process.env.VUE_APP_EXCHANGE_PAGE,
      remainingSeconds: 0, // Initial number of seconds (5 minutes)
      remainingTime: {
        minutes: 0,
        seconds: 0,
      },
      cardsArray: [],
      mergedObj: {},
      dashboardCards: {
        document: {
          general: {
            title: 'doc.general.title',
            pendingDescription: 'doc.general.pending.desc',
            rejectDescription: 'doc.general.reject.desc',
            cardType: 'document',
            link: { name: 'document-general' },
          },
          bank: {
            title: 'doc.bank.title',
            pendingDescription: 'doc.bank.pending.desc',
            rejectDescription: 'doc.bank.reject.desc',
            cardType: 'document',
            link: { name: 'document-bank' },
          },
        },
        ...(actvieModules.includes('FOREX') && {
          forex: {
            deposit: {
              wire: {
                title: 'forex.deposit.wire.title',
                description: 'forex.deposit.wire.desc',
                link: { name: 'forexModule-bank-deposit' },
              },
              crypto: {
                title: 'forex.deposit.crypto.title',
                description: 'forex.deposit.crypto.desc',
                link: { name: 'forexModule-crypto-deposit' },
              },
            },
            withdrawal: {
              crypto: {
                title: 'forex.withdrawal.crypto.title',
                description: 'forex.withdrawal.crypto.desc',
                link: { name: 'forexModule-crypto-withdrawal' },
              },
              wire: {
                title: 'forex.withdrawal.wire.title',
                description: 'forex.withdrawal.wire.desc',
                link: { name: 'forexModule-bank-withdrawal' },
              },
            },
          },
        }),
        ...(actvieModules.includes('CRYPTO') && {
          crypto: {
            deposit: {
              wire: {
                title: 'crypto.deposit.wire.title',
                description: 'crypto.deposit.wire.desc',
                link: '/customer-wallets/fiat/deposit/online',
              },
              crypto: {
                title: 'crypto.deposit.crypto.title',
                description: 'crypto.deposit.crypto.desc',
                link: '/crypto-wallets',
              },
            },
            withdrawal: {
              wire: {
                title: 'crypto.withdrawal.wire.title',
                description: 'crypto.withdrawal.wire.desc',
                link: '/customer-wallets/fiat/withdraw',
              },
              crypto: {
                title: 'crypto.withdrawal.crypto.title',
                description: 'crypto.withdrawal.crypto.desc',
                link: '/crypto-wallets',
              },
            },
          },
        }),
      },
    }
  },
  mounted() {
    profile.get().then(res => {
      if (!res.data.results.user.isActive) {
        this.resendActivationEmail()
      }
    }).finally(() => {
      this.show = false
    })
    profile.dashboard().then(res => {
      this.mergedObj = this.mergeObjects(this.dashboardCards, res.data.results)

      this.cardsArray = this.collectLeafObjects(this.mergedObj)
      console.log(this.mergedObj)
    })
  },
  methods: {
    mergeObjects(obj1, obj2) {
    // Create a new object to hold the merged values
      const result = {}

      // Merge properties of obj1
      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj1) {
      // eslint-disable-next-line no-prototype-builtins
        if (obj1.hasOwnProperty(key)) {
          result[key] = obj1[key]
        }
      }

      // Merge properties of obj2, recursively merging any object properties
      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj2) {
      // eslint-disable-next-line no-prototype-builtins
        if (obj2.hasOwnProperty(key)) {
          if (typeof obj2[key] === 'object' && obj2[key] !== null && result[key]) {
            result[key] = this.mergeObjects(result[key], obj2[key])
          } else {
            result[key] = obj2[key]
          }
        }
      }
      return result
    },
    collectLeafObjects(obj, result = []) {
      let isLeaf = true

      Object.keys(obj).forEach(key => {
        if (typeof obj[key] === 'object' && obj[key] !== null && key !== 'link') { // key !== 'link' becuse of link object
          isLeaf = false
          this.collectLeafObjects(obj[key], result)
        }
      })

      // If no properties were objects, it's a leaf object
      if (isLeaf) {
        result.push(obj)
      }

      return result
    },
    resendActivationEmail() {
      this.sendMaliLoading = true
      profile.reSendActivationMail({ email: this.$store.state.auth.user.email }).then(res => {
        this.remainingSeconds = res.data.results.remained_expiration_time
        if (this.remainingSeconds) {
          this.updateCountdown()
        }
        this.$swal({
          title: `${this.$t('activationEmailSended')}`,
          icon: 'success',
          timer: 2500,
          showConfirmButton: true,
        })
      }).catch(err => {
        if (err.data.status === 406) {
          this.remainingSeconds = err.data.results.remained_expiration_time
          if (this.remainingSeconds) {
            this.updateCountdown()
          }
        }
      })
        .finally(() => {
          this.sendMaliLoading = false
        })
    },
    updateCountdown() {
      if (this.remainingSeconds > 0) {
        this.remainingTime.minutes = Math.floor(this.remainingSeconds / 60)
        this.remainingTime.seconds = this.remainingSeconds % 60
        this.remainingSeconds -= 1

        setTimeout(this.updateCountdown, 1000) // Call itself after 1 second
      } else {
        this.remainingTime.minutes = 0
        this.remainingTime.seconds = 0
      }
    },
    formatTime(value) {
      return value.toString().padStart(2, '0')
    },
    // setFlow() {
    //   this.show = true
    //   if (this.$store.state.profile.profileInfo.isActive === '1') {
    //     this.isUserActive = true
    //     if (!this.$store.state.profile.profileInfo.is_deposited) {
    //       this.cardInfo = appFlow.deposit
    //     } else if (!this.$store.state.profile.profileInfo.mobile_is_verify
    //     || !this.$store.state.profile.profileInfo.profile_complete) {
    //       this.cardInfo = appFlow.profile_complete
    //     } else if (!this.$store.state.profile.profileInfo.document.general.exist) {
    //       this.cardInfo = appFlow.general
    //     } else if (!this.$store.state.profile.profileInfo.document.bank.exist) {
    //       this.cardInfo = appFlow.bank
    //     } else {
    //       this.user_complete = true
    //     }
    //   } else {
    //     this.isUserActive = false
    //   }
    //   this.show = false
    // },
  },
}
</script>
<style>
.card-center-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.buy-sell-btn{
  background-color: #f0c72b !important;
  border: none;
  color: #000 !important;
  animation: shake 10s infinite;
  animation-delay: 2s;

}
@keyframes shake {
  0% {
    transform: scale(1);
  }

  1% {
    transform: scale(1.2);
  }

  2% {
    transform: scale(1);
  }

  3% {
    transform: scale(1);
  }

  75% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.buy-sell-btn:focus {
  background-color:#f0c72b !important ;
}
</style>
